import React from "react";
import { ThreeCircles } from "react-loader-spinner";

const Loader = () => {
  return (
    <div className="loader_wrapper">
      <ThreeCircles
        visible={true}
        height="100"
        width="100"
        color="var(--secondary-color)"
        ariaLabel="three-circles-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
      <p>Loading...</p>
    </div>
  );
};

export default Loader;
