import React, { useEffect, useMemo, useState } from "react";
import "./Register.css";
import { Link, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import axios from "axios";
import Select from "react-select";
import countryList from "react-select-country-list";
import { useGlobalContext } from "../../context/context";
import { Slide, toast } from "react-toastify";

// Components
import { Button, Footer, Header } from "../../components";

// Icons
import {
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdVisibility,
} from "react-icons/md";
import { AiOutlineEyeInvisible } from "react-icons/ai";

const schema = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  middleName: yup.string().required(),
  email: yup.string().email().required(),
  phone: yup.string().required(),
  password: yup.string().min(6).max(14).required(),
  confirmpassword: yup
    .string()
    .required("Please confirm your password")
    .oneOf([yup.ref("password"), null], "Password must matched"),
});

const Register = () => {
  const [value, setValue] = useState("");
  const options = useMemo(() => countryList().getData(), []);
  const [selectedCountry, setselectedCountry] = useState(``);
  const [allCountries, setallCountries] = useState([]);
  const [checkbox, setCheckbox] = useState(false);
  const [visible, setVisible] = useState(true);
  const [loading, setloading] = useState(false);
  const toggleVisibility = () => setVisible(!visible);
  const toggleCheckBox = () => setCheckbox(!checkbox);
  const name = "Apex Crypto Investment";
  const navigate = useNavigate();
  const { baseUrl } = useGlobalContext();
  const currencyDetails = {
    symbol: "",
    name: "",
    flag: "",
  };

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  // Handle Registration
  const handleRegisterForm = (data) => {
    data.country = {
      name: currencyDetails.name,
      symbol: currencyDetails.symbol,
      flag: currencyDetails.flag,
    };

    if (checkbox) {
      setloading(true);
      axios
        .post(`${baseUrl}auth/register`, data)
        .then((data) => {
          if (data.status === 201) {
            toast.success("Registration Successful", { theme: "light" });
            setTimeout(() => {
              navigate("/auth/login");
            }, 2000);
            setloading(false);
          }
        })
        .catch((error) => {
          setloading(false);
          if (error?.response?.data?.keyPattern?.email === 1) {
            toast.error("User Already Exist", { transition: Slide });
          }
        });
    } else {
      toast.error("Please accept user terms and conditions", {
        transition: Slide,
      });
    }
  };

  // Handle Select Country
  const changeHandler = (value) => {
    setValue(value);
    setselectedCountry(
      allCountries.filter((country) => country.name.common === value.label)
    );
  };

  for (const currencyCode in selectedCountry[0]?.currencies) {
    if (
      Object.hasOwnProperty.call(selectedCountry[0]?.currencies, currencyCode)
    ) {
      const currency = selectedCountry[0]?.currencies[currencyCode];

      currencyDetails.symbol = currency?.symbol;
      currencyDetails.name = selectedCountry[0]?.name.common;
      currencyDetails.flag = selectedCountry[0]?.flags.png;
    }
  }

  const getCountries = () => {
    axios
      .get(`https://restcountries.com/v3.1/all`)
      .then((res) => {
        setallCountries(res.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getCountries();
    if (checkbox) {
      setCheckbox(false);
    }
  }, []);

  return (
    <>
      <Header />
      <section className="register_container">
        <h2 className="text_2">Let get you started with a {name} account</h2>
        <form
          className="register_form"
          onSubmit={handleSubmit((data) => handleRegisterForm(data))}
        >
          <div>
            <p className="text_text">Given Name</p>
            <input
              type="text"
              className="form_input"
              placeholder="Given Name"
              {...register("firstName", { required: true })}
            />
            <h4 className="error">{errors.firstName?.message}</h4>
          </div>
          <div>
            <p className="text_text">Middle Name</p>
            <input
              type="text"
              className="form_input"
              placeholder="Middle Name"
              {...register("middleName", { required: true })}
            />
            <h4 className="error">{errors.middleName?.message}</h4>
          </div>
          <div>
            <p className="text_text">Surname</p>
            <input
              type="text"
              className="form_input"
              placeholder="Family Name"
              {...register("lastName", { required: true })}
            />
            <h4 className="error">{errors.lastName?.message}</h4>
          </div>
          <div>
            <p className="text_text">Email</p>
            <input
              type="text"
              className="form_input"
              placeholder="name@email.com"
              {...register("email", { required: true })}
            />
            <h4 className="error">{errors.email?.message}</h4>
          </div>
          <div>
            <p className="text_text">Select Country</p>
            <Select options={options} onChange={changeHandler} value={value} />
          </div>
          <div>
            <p className="text_text">Mobile Number</p>
            <input
              type="text"
              className="form_input"
              placeholder="Mobile Number"
              {...register("phone", { required: true })}
            />
            <h4 className="error">{errors.phone?.message}</h4>
          </div>
          <div>
            <p className="text_text">Password</p>
            <div className="register_password form_input">
              <input
                type={visible ? "text" : "password"}
                placeholder="Your Password"
                {...register("password", { required: true })}
              />
              {visible ? (
                <AiOutlineEyeInvisible size={20} onClick={toggleVisibility} />
              ) : (
                <MdVisibility size={20} onClick={toggleVisibility} />
              )}
            </div>
            <h4 className="error">{errors.password?.message}</h4>
          </div>
          <div>
            <p className="text_text">Confirm Password</p>
            <div className="register_password form_input">
              <input
                type={visible ? "text" : "password"}
                placeholder="Confirm Password"
                {...register("confirmpassword", { required: true })}
              />
              {visible ? (
                <AiOutlineEyeInvisible size={20} onClick={toggleVisibility} />
              ) : (
                <MdVisibility size={20} onClick={toggleVisibility} />
              )}
            </div>
            <h4 className="error">{errors.confirmpassword?.message}</h4>
          </div>
          <div className="terms_container">
            {checkbox ? (
              <MdCheckBox onClick={toggleCheckBox} size={30} />
            ) : (
              <MdCheckBoxOutlineBlank onClick={toggleCheckBox} size={30} />
            )}
            <p className="terms">
              Our User Agreement has changed, including the Dispute Resolution
              provision. By creating this account, you agree to our{" "}
              <Link>USER AGREEMENT</Link> and
              <Link to={"/privacy"}> PRIVACY POLICY</Link>.
            </p>
          </div>
          <Button
            title={loading ? "Loading..." : "Sign Up"}
            id={""}
            action={() => ""}
          />
        </form>
      </section>
      <Footer />
    </>
  );
};

export default Register;
