import React, { useState } from "react";

// Components
import { Button, Footer, Header } from "../../components";
import { useGlobalContext } from "../../context/context";
import axios from "axios";
import { useForm } from "react-hook-form";
import { Slide, toast } from "react-toastify";

const ForgetPassword = () => {
  const [loading, setloading] = useState(false);
  const name = "Apex Crypto Investment";
  const { baseUrl } = useGlobalContext();
  const { register, handleSubmit } = useForm();

  const handleForgetPassword = (data) => {
    setloading(true);
    axios
      .post(`${baseUrl}auth/forget`, data)
      .then((response) => {
        if (response.status === 200) {
          setloading(false);
          toast.success("Please Check Your Email For Reset Password Link", {
            transition: Slide,
          });
        }
      })
      .catch((err) => {
        setloading(false);
        if (err?.response?.data === "Wrong credentials") {
          toast.error("Email is not Registered.", {
            theme: "dark",
          });
        }
      });
  };

  return (
    <>
      <Header />
      <section className="register_container login_container forget_password">
        <h2 className="text_2">Forgot Password</h2>
        <p className="terms">
          Please enter the email address associated with your {name} account
          below. Resetting a forgotten password will logout other devices and
          will result in a 72-hour hold on cryptocurrency withdrawals.
        </p>
        <form
          className="register_form"
          onSubmit={handleSubmit((data) => handleForgetPassword(data))}
        >
          <div>
            <p className="text_text">Email address</p>
            <input
              style={{ marginBottom: 20 }}
              type="text"
              className="form_input"
              placeholder="name@email.com"
              {...register("email", { required: "Email is Required" })}
            />
          </div>
          <Button
            title={loading ? "Loading..." : "Continue"}
            action={() => ""}
          />
        </form>
      </section>
      <Footer />
    </>
  );
};

export default ForgetPassword;
