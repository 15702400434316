import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useGlobalContext } from "../../context/context";

// Components
import { Button, Footer, Header } from "../../components";
import ReCAPTCHA from "react-google-recaptcha";

// Icons
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { MdVisibility } from "react-icons/md";
import { useForm } from "react-hook-form";
import { Slide, toast } from "react-toastify";

const schema = yup.object({
  email: yup.string().email().required(),
  password: yup.string().min(6).max(14).required(),
});

const Login = () => {
  const [visible, setVisible] = useState(true);
  const [loading, setloading] = useState(false);
  const [wait, setwait] = useState(false);
  const [approvedLogin, setApprovedLogin] = useState(false);
  const key = "6Ldp0JgpAAAAALA3St4guqM8HRHCWkIFSGRW5tdd";

  const name = "Apex Crypto Investment";
  const { baseUrl, getUser, getAllWithdrawalss } = useGlobalContext();
  const navigate = useNavigate();

  const toggleVisibility = () => setVisible(!visible);

  // const handleValidate = (value) => {
  //   if (value.length) {
  //     setApprovedLogin(true);
  //   }
  // };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const loginUser = (data) => {
    setloading(true);
    setTimeout(() => {
      setwait(true);
    }, 10000);
    axios
      .post(`${baseUrl}auth/login`, data)
      .then((data) => {
        if (data.status === 200) {
          setloading(false);
          setwait(false);
          getUser();
          sessionStorage.setItem("user", JSON.stringify(data.data));
          sessionStorage.setItem(
            "accessToken",
            JSON.stringify(data.data.accessToken)
          );
          toast.success("User verified", { theme: "light" });
          setTimeout(() => {
            navigate("/overview");
          }, 2500);
        }
      })
      .catch((err) => {
        if (err?.response?.data === "Wrong credentials") {
          toast.error("Wrong Credentials");
        }
        setloading(false);
        setwait(false);
      });
  };

  useEffect(() => {
    getAllWithdrawalss();
  }, []);

  return (
    <>
      <Header />
      <section className="register_container login_container">
        <h2 className="text_2">Sign In</h2>
        <form
          className="register_form login_form"
          onSubmit={handleSubmit((data) => loginUser(data))}
        >
          <div>
            <p className="text_text">Email</p>
            <input
              type="email"
              className="form_input"
              placeholder="name@email.com"
              {...register("email", { required: "Email is required" })}
            />
            <h4 className="error">{errors.email?.message}</h4>
          </div>
          <div>
            <p className="text_text">Password</p>
            <div className="register_password form_input">
              <input
                type={visible ? "text" : "password"}
                placeholder="Your Password"
                {...register("password", { required: "Password is required" })}
              />
              {visible ? (
                <AiOutlineEyeInvisible size={20} onClick={toggleVisibility} />
              ) : (
                <MdVisibility size={20} onClick={toggleVisibility} />
              )}
            </div>
          </div>
          <h4 className="error">{errors.password?.message}</h4>
          {/* <ReCAPTCHA sitekey={key} onChange={handleValidate} /> */}
          <div className="terms_container">
            <p className="terms">
              Don't have a {name} account?
              <Link to={"/auth/register"}> Create a new Account</Link>
            </p>
          </div>

          <Button
            title={
              wait
                ? "Please wait! While we verify your login"
                : loading
                ? "loading..."
                : "Login"
            }
            action={() => ""}
          />
        </form>
        <Link to={"/auth/forget"} className="text_text forgetPassword_text">
          Forget your Password?
        </Link>
      </section>
      <Footer />
    </>
  );
};
export default Login;
