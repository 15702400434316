import ModalThree from "./pages/UserDashboard/components/Modals/DepositModal/ModalThree";
import ModalStepOne from "./pages/UserDashboard/components/Modals/ModalStepOne/ModalStepOne";
import ModalStepTwo from "./pages/UserDashboard/components/Modals/ModalStepTwo/ModalStepTwo";
import WithdrawalSecondModal from "./pages/UserDashboard/components/Modals/WithdrawalModal/WithdrawalSecondModal";

export const checPath = (path) => {
  return path === "/" ? "Dashboard" : path;
};

export const checkSteps = (steps, register, paymentType, statusText) => {
  return steps === 1 ? (
    <ModalStepOne register={register} statusText={statusText} />
  ) : steps === 2 ? (
    <ModalStepTwo paymentType={paymentType} />
  ) : steps === 4 ? (
    <WithdrawalSecondModal register={register} />
  ) : steps === 3 ? (
    <ModalThree paymentType={paymentType} />
  ) : (
    ""
  );
};
