import React from "react";
import "./Footer.css";

// Components
import Button from "../Button/Button";

// Images
import footerLogo from "../../assets/elite.png";

// Icons
import { AiOutlineInstagram } from "react-icons/ai";
import { FaFacebookF, FaLinkedin, FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  const name = "apexcryptoinvestment";

  return (
    <footer>
      <div className="footer_item_one">
        <div className="footer_newslet_sect">
          <img src={footerLogo} alt="" />
          <p className="text">Stay up to date</p>
          <input type="text" />
          <Button title={"Subscribe"} />
        </div>
        <div className="footer_about_sect">
          <h3 className="">Company</h3>
          <p className="text">About Us</p>
          <p className="text">Active Trader</p>
          <p className="text">Credit Card</p>
          <p className="text">Derivatives</p>
        </div>
        <div className="footer_partners_sect">
          <h3 className="">Partners</h3>
          <p className="text">Samsung</p>
          <p className="text">TradingView</p>
          <p className="text">Brave</p>
          <p className="text">Nifty Gateway</p>
        </div>
        <div className="footer_support_sect">
          <h3 className="">Support</h3>
          <p className="text">Contact Us</p>
          <p className="text">Support Center</p>
          <p className="text">Privacy Policy</p>
          <p className="text">Derivatives</p>
        </div>
      </div>
      <div className="footer_item_two">
        <div className="footer_icon_container">
          <FaFacebookF />
        </div>
        <div className="footer_icon_container">
          <FaLinkedin />
        </div>
        <div className="footer_icon_container">
          <FaTwitter />
        </div>
        <div className="footer_icon_container">
          <AiOutlineInstagram />
        </div>
      </div>
      <div className="footer_item_three">
        <p>© Copyright 2024 {name} Trust Company, LLC.</p>
        <p>Legal Notice</p>
        <p>
          This site is protected by reCAPTCHA and the Google
          <Link to={"/privacy"}>Privacy Policy</Link> and{" "}
          <Link to={"/terms"}>Terms of Service</Link>
          apply.
        </p>
        <p></p>
      </div>
    </footer>
  );
};

export default Footer;
