import React from "react";
import "./About.css";

// Component
import { Footer, Header, PageFooter } from "../../components";

// Images
import cube from "../../assets/cube.svg";
import checkMark from "../../assets/check-mark.svg";
import paper from "../../assets/paper.svg";
import shield from "../../assets/shield.svg";
import company from "../../assets/company.webp";

// Icons

const About = () => {
  const name = "Apex Crypto Investment";
  return (
    <section className="about_sect">
      <Header />
      <div className="about_hero_sect">
        <h2 className="text_2">
          Our is to unlock the next era of
          <span className="secondary_color">financial</span>,
          <span className="secondary_color">creative</span> and
          <span className="secondary_color">personal</span>
          <span className="secondary_color">freedom</span>.
        </h2>
        <h1 className="header_text bold_home_text">
          We are here to help you on your
          <span className="secondary_color">journey</span>.
        </h1>
      </div>
      <div className="about_first_sect">
        <p className="text_text">
          We build crypto products that are simple, elegant, and secure. Whether
          you are an individual or an institution, we want to help you buy,
          sell, and store your bitcoin and cryptocurrency.
        </p>
      </div>
      <div className="about_second_sect">
        <div className="about_sect_two_item_one">
          <h2 className="text_2">The Four Pillars of {name}</h2>
          <p className="text_text">
            We are committed to earning and maintaining your trust. We believe
            that in order to do so, we must invest in our four pillars for the
            long-term, as they are the inputs that generate a trust output.
          </p>
          <div className="about_pillars_sect">
            <div className="about_pillars_sect_items">
              <img src={cube} alt="" />
              <p className="text_text">Product</p>
            </div>
            <div className="about_pillars_sect_items">
              <img src={shield} alt="" />
              <p className="text_text">Security</p>
            </div>
            <div className="about_pillars_sect_items">
              <img src={paper} alt="" />
              <p className="text_text">License</p>
            </div>
            <div className="about_pillars_sect_items">
              <img src={checkMark} alt="" />
              <p className="text_text">Compliance</p>
            </div>
          </div>
          <div className="about_pillars_sect_text_container">
            <div>
              <p className="text_text">
                Crypto has the capacity to force a redesign of the Internet, our
                financial system, and money in a way that fosters and protects
                the rights and dignity of the individual. If we are successful,
                we could make as great of a contribution to personal freedom as
                the invention of the printing press, the personal computer, and
                the early, open Internet.
              </p>
              <p className="text_text">
                Crypto is not just a technology, it’s a movement. But it won’t
                happen unless we build simple, elegant, and secure ways for
                individuals and institutions around the world to discover and
                interact with this new frontier.
              </p>
            </div>
            <div>
              <p className="text_text">
                We founded {name} with a “security-first” mentality and ethos of
                asking for permission, not forgiveness. We have worked hard to
                provide you with a high-integrity choice and we look forward to
                earning and maintaining your trust.
              </p>
              <p className="text_text">
                {name} is a New York trust company regulated by the New York
                State Department of Financial Services (NYSDFS). We are subject
                to capital reserve requirements, cybersecurity requirements, and
                banking compliance standards set forth by the NYSDFS and the New
                York Banking Law. {name} is also a fiduciary and Qualified
                Custodian.
              </p>
            </div>
          </div>
          <div className="pillars_company_sect">
            <img src={company} alt="" />
            <p className="text_text">
              {name} is the world’s first SOC 1 Type 2 and SOC 2 Type 2
              certified crypto exchange and custodian. Learn more about our
              commitment to security.
            </p>
          </div>
        </div>
      </div>
      <div className="column_about_first_sect about_first_sect">
        <h3 className="text_2">Join our mission</h3>
        <p className="text_text">
          Our mission is to unlock the next era of financial, creative and
          personal freedom.
        </p>
        <p className="text_text">Visit Career page</p>
      </div>
      <PageFooter
        title={"The most trusted crypto-native finance platform"}
        text={`Create a free ${name} account in minutes`}
      />
      <Footer />
    </section>
  );
};

export default About;
