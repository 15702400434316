import React, { useEffect, useState } from "react";
import axios from "axios";
import { ColorRing } from "react-loader-spinner";

const Price = () => {
  const name = "Apexcryptoinvestment";
  const [cryptocurrencies, setCurrencies] = useState([]);
  const [loading, setLoading] = useState(false);
  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const getBitcoinPrice = () => {
    setLoading(true);
    axios
      .get(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd`)
      .then((data) => {
        setCurrencies(data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getBitcoinPrice();
  }, []);
  return (
    <div className="prices_container price_container">
      <div className="prices_table">
        <div className="prices_table_header">
          <div className="prices_name">
            <p className="text_text">Name</p>
          </div>
          <div className="prices_price">
            <p className="text_text">Price</p>
          </div>
          <div className="prices_hour">
            <p className="text_text">24hr Change</p>
          </div>
          <div className="prices_percent">
            <p className="text_text">% Change</p>
          </div>
          <div className="prices_market">
            <p className="text_text">Market Cap</p>
          </div>
        </div>
        {loading ? (
          <div className="loader_container">
            <ColorRing
              visible={true}
              height="40"
              width="40"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={["#010304", "#010304", "#010304", "#010304", "#010304"]}
            />
            <p>Loading...</p>
          </div>
        ) : (
          cryptocurrencies.map((item, index) => {
            const {
              name,
              symbol,
              price_change_24h,
              price_change_percentage_24h,
              market_cap,
              image,
              current_price,
            } = item;
            return (
              <div key={index} className="prices_table_body">
                <div className="prices_name">
                  <img src={image} alt={name} />
                  <div className="prices_name_item">
                    <h3>{name}</h3>
                    <p>
                      {symbol}
                      {"USD"}
                    </p>
                  </div>
                </div>
                <div className="prices_price">
                  <p className="text_text"> {USDollar.format(current_price)}</p>
                </div>
                <div className="prices_hour">
                  <p className="text_text">
                    {USDollar.format(price_change_24h)}
                  </p>
                </div>
                <div className="prices_percent">
                  <p className="text_text">
                    {USDollar.format(price_change_percentage_24h)}
                  </p>
                </div>
                <div className="prices_market">
                  <p className="text_text">{USDollar.format(market_cap)}</p>
                </div>
              </div>
            );
          })
        )}
      </div>
      <p>
        The pricing data and asset description above are for general
        informational purposes only and are not investment advice. Buying,
        selling, and trading cryptocurrency involves risks. You should consult
        with your own appropriately qualified and licensed advisors before
        engaging in any transaction on {name}. Some data is provided by outside
        sources, these third parties are not affiliated with {name}.
      </p>
    </div>
  );
};

export default Price;
