import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

// Styles
import {
  DepositAside,
  DepositModalWrappper,
  DepositHeader,
  DepositLeft,
  BtnWrapper,
} from "./DepositModal.styles";
import { Text } from "../../general.styles";
import { Btn } from "../../../pages/OverView/OverView.styles";

// Icons
import { IoClose } from "react-icons/io5";
import { ColorRing } from "react-loader-spinner";
import { checkSteps } from "../../../../../helper-methods";
import axios from "axios";
import { useGlobalContext } from "../../../../../context/context";
import { useNavigate } from "react-router-dom";

const DepositModal = ({ toggleStatus, depositStatus }) => {
  const accessToken = JSON.parse(sessionStorage.getItem("accessToken"));
  const { firstName, lastName, _id, email } =
    JSON.parse(sessionStorage.getItem("user")) || {};
  const { baseUrl, paymentType, proofImage, setPaymentType, setProofImage } =
    useGlobalContext();
  const [steps, setSteps] = useState(1);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const statusText = "deposit";

  const { register, handleSubmit } = useForm();

  const validateSteps = (data) => {
    const depositForm = new FormData();
    depositForm.append("amount", data.amount);
    depositForm.append("status", "Pending");
    depositForm.append("userId", _id);
    depositForm.append("transactiontype", paymentType);
    depositForm.append("name", `${firstName} ${lastName}`);
    depositForm.append("email", email);
    for (let i = 0; i < proofImage.length; i++) {
      depositForm.append("image", proofImage[i]);
    }
    data.transactiontype = paymentType;
    data.status = "Pending";
    data.userId = "12345";
    data.name = "Eli Eli";
    data.email = "eli@gmail.com";
    data.image = proofImage;

    if (data.amount !== "" && steps === 1) {
      setLoading(true);
      setTimeout(() => {
        increamentStep(2);
        setLoading(false);
      }, 2000);
    } else if (data.amount === "" && steps === 1) {
      toast.error("Amount is required");
    }
    if (data.transactiontype !== "" && steps === 2) {
      setLoading(true);
      setTimeout(() => {
        increamentStep(3);
        setLoading(false);
      }, 2000);
    } else if (data.transactiontype === "" && steps === 2) {
      toast.error("Please Select Payment Method");
    }
    if (data.amount && data.transactiontype && data.image.length > 0) {
      setLoading(true);
      axios
        .post(`${baseUrl}deposit`, depositForm, {
          headers: { token: accessToken },
        })
        .then((response) => {
          if (response.status === 200) {
            setLoading(false);
            setPaymentType("");
            setProofImage([]);
            toast.success("Depoit Processing");

            setTimeout(() => {
              navigate("/transaction");
              toggleStatus();
              setSteps(1);
            }, 2000);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  const increamentStep = (value) => {
    setSteps(value);
  };
  return (
    <DepositModalWrappper width={depositStatus}>
      <DepositLeft onClick={toggleStatus}></DepositLeft>
      <DepositAside width={depositStatus}>
        <DepositHeader>
          <Text size="20px" fontWeight={500} color="white" mobileSize="17px">
            Deposit Money
          </Text>
          <IoClose
            size={28}
            color="rgb(160, 210, 254)"
            onClick={toggleStatus}
          />
        </DepositHeader>
        {checkSteps(steps, register, paymentType, statusText, depositStatus)}
        <BtnWrapper>
          <Btn
            fontWeight={600}
            onClick={handleSubmit((data) => validateSteps(data))}
            width="100%"
            bgimage="linear-gradient(98.7deg,#5240f9 19.95%,#f3526d 89.51%)"
          >
            {loading ? (
              <ColorRing
                visible={true}
                height="40"
                width="100"
                ariaLabel="color-ring-loading"
                wrapperStyle={{}}
                wrapperClass="color-ring-wrapper"
                colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
              />
            ) : (paymentType === "bank" && steps === 3) ||
              (paymentType === "crypto" && steps === 3) ? (
              "I have completed deposit"
            ) : (
              "Procced"
            )}
          </Btn>
        </BtnWrapper>
      </DepositAside>
    </DepositModalWrappper>
  );
};

export default DepositModal;
