import React from "react";
import "./Partner.css";
import { Footer, Header } from "../../components";

// Images
import samsung from "../../assets/samsung.webp";
import samsungsvg from "../../assets/SamsungBlockchain.svg";
import braveNft from "../../assets/brave_nft_trading.webp";
import statestreet from "../../assets/statestreet.webp";
import statestreetsvg from "../../assets/statestreetsvg.svg";
import tradingview from "../../assets/tradingview.webp";
import tradingviewsvg from "../../assets/tradingviewsvg.svg";
import nft from "../../assets/nft.webp";
import nftsvg from "../../assets/nftsvg.svg";
import brave from "../../assets/brave.webp";
import bravesvg from "../../assets/bravesvg.svg";
import taxbitsvg from "../../assets/taxbit.webp";
import taxbit from "../../assets/taxbitsvg.webp";
import shield from "../../assets/shield.svg";
import enable from "../../assets/check-mark.svg";
import sync2 from "../../assets/sync2.svg";
import support from "../../assets/chat.svg";
import lock from "../../assets/lock.svg";
import coverage from "../../assets/coverage.svg";

const Partners = () => {
  const name = "Apex Crypto Investment";

  return (
    <section className="partners_page">
      <Header />
      <div className="partners_sect_one">
        <div className="partners_sect_text_sect">
          <h1 className="header_text">Our partners</h1>
          <p className="text_text">
            {name} is partnering with leading brands in cryptocurrency and
            beyond to take this digital frontier in new and exciting directions.
          </p>
          <p className="text_text">
            Join us on this journey in building the future of money.
          </p>
        </div>
        <div className="partners_sect_one_img_sect">
          <img src={braveNft} alt="" />
        </div>
      </div>
      <h2 className="text_2">Moving crypto forward, together</h2>
      <div className="partners_sect_one partners_sect_two">
        <div className="partners_sect_text_sect">
          <img src={samsungsvg} alt="" />
          <p className="text_text">
            {name}’s integration with the Samsung Blockchain Wallet allows users
            to seamlessly connect to the {name} Mobile App to trade crypto.
            {name} is helping bring crypto to the fingertips of Samsung
            Blockchain Wallet users across the United States and Canada.
          </p>
          <a
            href="https://www.samsung.com/global/galaxy/apps/samsung-blockchain/"
            target="_blank"
            rel="noreferrer"
            className="text_text"
          >
            Learn more
          </a>
        </div>
        <div className="partners_sect_one_img_sect">
          <img src={samsung} alt="" />
        </div>
      </div>
      <div className="partners_sect_one partners_sect_two partners_sect_two_reverse">
        <div className="partners_sect_text_sect">
          <img src={statestreetsvg} alt="" />
          <p className="text_text">
            Financial institutions can store their digital assets with {name}
            Custody® and now receive reporting and holdings information for
            these assets via State Street. Our partnership with State Street is
            a major milestone for {name} and for cryptocurrency as a mainstream
            asset class.
          </p>
          <a
            href="https://www.statestreet.com/us/en/asset-manager"
            target="_blank"
            rel="noreferrer"
            className="text_text"
          >
            Learn more
          </a>
        </div>
        <div className="partners_sect_one_img_sect">
          <img src={statestreet} alt="" />
        </div>
      </div>
      <div className="partners_sect_one partners_sect_two">
        <div className="partners_sect_text_sect">
          <img src={tradingviewsvg} alt="" />
          <p className="text_text">
            {name}’s integration with TradingView allows users to execute trades
            on the {name} Exchange directly from the TradingView interface,
            which gives them access to advanced charting tools and a robust
            trading community. TradingView users are automatically placed in
            {name}’s lowest fee tier when trading on their platform.
          </p>
          <a
            href="https://www.tradingview.com/"
            target="_blank"
            rel="noreferrer"
            className="text_text"
          >
            Learn more
          </a>
        </div>
        <div className="partners_sect_one_img_sect trading_view_image">
          <img src={tradingview} alt="" />
        </div>
      </div>
      <div className="partners_sect_one partners_sect_two partners_sect_two_reverse">
        <div className="partners_sect_text_sect">
          <img src={nftsvg} alt="" />
          <p className="text_text">
            Nifty Gateway® the premier platform to buy, sell, and store digital
            art and collectibles, is a {name}-owned company. New collections of
            non-fungible tokens (nifties) are regularly released on the Nifty
            Marketplace — and selling out fast. The world of art is evolving and
            leaping onto the blockchain. Explore this exciting new medium today.
          </p>
          <a
            href="https://www.niftygateway.com/"
            target="_blank"
            rel="noreferrer"
            className="text_text"
          >
            Learn more
          </a>
        </div>
        <div className="partners_sect_one_img_sect">
          <img src={nft} alt="" />
        </div>
      </div>
      <div className="partners_sect_one partners_sect_two">
        <div className="partners_sect_text_sect">
          <img src={bravesvg} alt="" className="brave_image" />
          <p className="text_text">
            The integration of the {name} Trading Widget into the Brave browser
            allows Brave users to buy and sell cryptocurrency, view their {name}
            account balances, and access their deposit addresses – all, without
            ever having to leave the Brave ecosystem. Brave Creators can also
            now custody their earnings with {name}.
          </p>
          <a
            href="https://brave.com/"
            target="_blank"
            rel="noreferrer"
            className="text_text"
          >
            Learn more
          </a>
        </div>
        <div className="partners_sect_one_img_sect trading_view_image">
          <img src={brave} alt="" />
        </div>
      </div>
      <div className="partners_sect_one partners_sect_two partners_sect_two_reverse">
        <div className="partners_sect_text_sect">
          <img src={taxbitsvg} alt="" className="brave_image" />
          <p className="text_text">
            Nifty Gateway® the premier platform to buy, sell, and store digital
            art and collectibles, is a {name}-owned company. New collections of
            non-fungible tokens (nifties) are regularly released on the Nifty
            Marketplace — and selling out fast. The world of art is evolving and
            leaping onto the blockchain. Explore this exciting new medium today.
          </p>
          <a
            href="https://taxbit.com/"
            target="_blank"
            rel="noreferrer"
            className="text_text"
          >
            Learn more
          </a>
        </div>
        <div className="partners_sect_one_img_sect">
          <img src={taxbit} alt="" />
        </div>
      </div>
      <h2 className="text_2">Why partner with {name}?</h2>
      <div className="exchange_last_sect_flex">
        <div>
          <img src={sync2} alt="" />
          <p className="text_text">
            The most trusted crypto exchange in the world
          </p>
        </div>
        <div>
          <img src={support} alt="" />
          <p className="text_text">
            Expert customer support team available 24/7
          </p>
        </div>
        <div>
          <img src={enable} alt="" />
          <p className="text_text">
            Full KYC due-diligence to maintain compliance with the Bank Secrecy
            Act and Anti-Money Laundering regulations
          </p>
        </div>
        <div>
          <img src={shield} alt="" />
          <p className="text_text">
            State of the art cyber security solutions for customer data and
            funds
          </p>
        </div>
        <div>
          <img src={coverage} alt="" />
          <p className="text_text">
            Coverage against certain types of losses from our hot wallet, and
            $75 million in cold storage coverage, with industry-leading
            insurance provider, also covering certain types of losses
          </p>
        </div>
        <div>
          <img src={lock} alt="" />
          <p className="text_text">
            Institutional-grade custody of more than 30 different digital assets
          </p>
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default Partners;
