import React, { useEffect, useState } from "react";
import "./styles.css";

// Components
import { DashboardHeader, EmptyWrapper, MiniHeader } from "../../components";

// Styles
import { TransactionBody, TransactionWrapper } from "./Transaction.styles";
import { useGlobalContext } from "../../../../context/context";
import moment from "moment";

// Images

const Transaction = () => {
  const {
    depositHistory,
    depositLoading,
    getAllDeposits,
    moneyFormatter,
    withdrawLoading,
    withdrawHistory,
    getAllWithdrawals,
  } = useGlobalContext();
  const accessToken = JSON.parse(sessionStorage.getItem("accessToken"));
  const {
    country: { symbol },
  } = JSON.parse(sessionStorage.getItem("user"));

  useEffect(() => {
    getAllDeposits(accessToken);
    getAllWithdrawals(accessToken);
  }, []);
  return (
    <TransactionWrapper>
      <DashboardHeader pathname={"Transactions"} />
      <MiniHeader />
      <TransactionBody>
        {depositHistory.length || withdrawHistory.length ? (
          <>
            {depositHistory.length && (
              <div className="transaction_wrapper">
                <div className="transaction_wrapper_header">
                  <div className="transaction_sn">
                    <h3>S/N</h3>
                  </div>
                  <div className="transaction_time">
                    <h3>Time</h3>
                  </div>
                  <div className="transaction_amount">
                    <h3>Amount</h3>
                  </div>

                  <div className="transaction_ref">
                    <h3>Reference</h3>
                  </div>
                  <div className="transaction_status">
                    <h3>Status</h3>
                  </div>
                  <div className="transaction_method">
                    <h3>Payment Method</h3>
                  </div>
                  <div className="transaction_type">
                    <h3>Type</h3>
                  </div>
                </div>
                {depositHistory.map((deposit, index) => {
                  const {
                    referenceNumber,
                    amount,
                    transactiontype,
                    createdAt,
                    status,
                  } = deposit;
                  const sn = index + 1;
                  return (
                    <div
                      key={index}
                      className="transaction_wrapper_header transaction_wrapper_body"
                    >
                      <div className="transaction_sn">
                        <p>{sn}</p>
                      </div>
                      <div className="transaction_time">
                        <p>{moment(createdAt).format("MMMM Do YYYY, h:mm")}</p>
                      </div>
                      <div className="transaction_amount">
                        <p>
                          {symbol}
                          {moneyFormatter.format(amount)}
                        </p>
                      </div>

                      <div className="transaction_ref">
                        <p>{referenceNumber}</p>
                      </div>
                      <div
                        className={`transaction_status ${
                          status === "Pending"
                            ? "transaction_status_text_processing"
                            : status === "false"
                            ? "transaction_status_text_false"
                            : "transaction_status_text_true"
                        }`}
                      >
                        <p
                          className={`transaction_status_text  ${
                            status === "Pending"
                              ? ""
                              : status === "false"
                              ? "transaction_status_text_false_text"
                              : "transaction_status_text_true_text"
                          }`}
                        >
                          {status === "Pending"
                            ? "Pending"
                            : status === "false"
                            ? "Failed"
                            : "Successful"}
                        </p>
                      </div>
                      <div className="transaction_method">
                        <p className="transaction_ref_text">
                          {transactiontype}
                        </p>
                      </div>
                      <div className="transaction_type">
                        <p>Deposit</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            {withdrawHistory.length > 0 && (
              <div style={{ marginTop: 40 }} className="transaction_wrapper">
                <div className="transaction_wrapper_header">
                  <div className="transaction_sn">
                    <h3>S/N</h3>
                  </div>
                  <div className="transaction_time">
                    <h3>Time</h3>
                  </div>
                  <div className="transaction_amount">
                    <h3>Amount</h3>
                  </div>

                  <div className="transaction_ref">
                    <h3>Reference</h3>
                  </div>
                  <div className="transaction_status">
                    <h3>Status</h3>
                  </div>
                  <div className="transaction_method">
                    <h3>Payment Method</h3>
                  </div>
                  <div className="transaction_type">
                    <h3>Type</h3>
                  </div>
                </div>
                {withdrawHistory.map((deposit, index) => {
                  const {
                    referenceNumber,
                    amount,
                    transactiontype,
                    createdAt,
                    status,
                  } = deposit;
                  const sn = index + 1;
                  return (
                    <div
                      key={index}
                      className="transaction_wrapper_header transaction_wrapper_body"
                    >
                      <div className="transaction_sn">
                        <p>{sn}</p>
                      </div>
                      <div className="transaction_time">
                        <p>{moment(createdAt).format("MMMM Do YYYY, h:mm")}</p>
                      </div>
                      <div className="transaction_amount">
                        <p>
                          {symbol}
                          {moneyFormatter.format(amount)}
                        </p>
                      </div>

                      <div className="transaction_ref">
                        <p>{referenceNumber}</p>
                      </div>
                      <div
                        className={`transaction_status ${
                          !status
                            ? "transaction_status_text_processing"
                            : "transaction_status_text_true"
                        }`}
                      >
                        <p
                          className={`transaction_status_text  ${
                            !status ? "" : "transaction_status_text_true_text"
                          }`}
                        >
                          {!status ? "Pending" : "Successful"}
                        </p>
                      </div>
                      <div className="transaction_method">
                        <p className="transaction_ref_text">
                          {transactiontype}
                        </p>
                      </div>
                      <div className="transaction_type">
                        <p>Withdraw</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </>
        ) : (
          <EmptyWrapper />
        )}
      </TransactionBody>
    </TransactionWrapper>
  );
};

export default Transaction;
